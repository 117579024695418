import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import PageWrapper from '../components/PageWrapper';
import SEO from '../components/SEO/SEO';
import Image from 'gatsby-image';

export const AboutPageTemplate = ({
  title,
  image,
  content,
  contentComponent,
  isPreview,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <PageWrapper title={title}>
      <div className='columns is-vcentered is-variable is-3-desktop is-8-widescreen'>
        <div className='column'>
          {!isPreview ? (
            <Image fluid={image} alt='portrett' />
          ) : (
            <img src={image} alt='portrett' />
          )}
        </div>
        <div className='column'>
          <PageContent className='content' content={content} />
        </div>
      </div>
    </PageWrapper>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  isPreview: PropTypes.bool,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout color='black'>
      <SEO title='Om oss' />
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        image={
          post.frontmatter.image
            ? post.frontmatter.image.childImageSharp.fluid
            : post.frontmatter.image
        }
        content={post.html}
        isPreview={false}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
