import React from 'react';
import Grid from '@material-ui/core/Grid';

const PageWrapper = ({ title, children }) => (
  <Grid
    className='page-wrapper'
    container
    spacing={0}
    direction='row'
    justify='center'
    alignItems='center'
  >
    <Grid item xs={12} sm={10} md={9}>
      <div className='container'>
        <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
          {title}
        </h1>
        {children}
      </div>
    </Grid>
  </Grid>
);

export default PageWrapper;
